//
//
//
//
//
//
//
//
//
//
//
//

//tab对应的组件
import Headers from "@/components/Headers.vue"

export default {
  data(){
    return {
      taglist : [
        {
          path : '/main/account/eiimprovement',
          name : '企业信息完善',
          style : 'transform: translateX(0);;z-index : 4;'
        },
        {
          path : '/main/account/changepwd',
          name : '密码修改',
          style : 'transform : translateX(-10px);z-index : 3;'
        },
        {
          path : '/main/account/equipment',
          name : '备品备件',
          style : 'transform : translateX(-10px);z-index : 3;'
        }
      ]
    }
  },
  components : {
    Headers
  }
}
