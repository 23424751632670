//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data(){
    return {
      oldpwd : '',
      newpwd : '',
      reallypwd : ''
    }
  }
}
