//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Starratings from './Starratings'
export default {
  components: {
    Starratings,
  },
}
